* {
  margin: 0;
}


#body {
  animation: colorchange 24s ease-in-out 1s infinite; /* animation-name followed by duration in seconds*/
     /* you could also use milliseconds (ms) or something like 2.5s */
  -webkit-animation: colorchange 24s ease-in-out 1s infinite alternate; /* Chrome and Safari */
  width: 100vw;
  overflow-y: auto;
}

@keyframes colorchange
{
  0%   {background: linear-gradient(135deg, #FC466B 0%, #3F5EFB 100%); }
  25%  {background: linear-gradient(135deg, #0700b8 0%, #00ff88 100%); }
  50%  {background: linear-gradient(135deg, #00C9FF 0%, #92FE9D 100%); }
  75%  {background: linear-gradient(135deg, #00d2ff 0%, #3a47d5 100%); }
  100%   {background: linear-gradient(135deg, #FC466B 0%, #3F5EFB 100%); }  
}

@-webkit-keyframes colorchange /*d Chrome - necessary duplicate */
{
  0%   {background: linear-gradient(135deg, #FC466B 0%, #3F5EFB 100%); }
  25%  {background: linear-gradient(135deg, #0700b8 0%, #00ff88 100%); }
  50%  {background: linear-gradient(135deg, #00C9FF 0%, #92FE9D 100%); }
  75%  {background: linear-gradient(135deg, #00d2ff 0%, #3a47d5 100%); }
  100%   {background: linear-gradient(135deg, #FC466B 0%, #3F5EFB 100%); }  
}
#message {
  padding-top: 50px;
  width: 50vw;
  margin: auto;
}

#message {
text-align: center;
font-family: ff1;
}
h1 {
font-size: 4em;
color: white;
}

h3 {
  font-size: 2.2em;
  text-align: right;
  color: white;
}

#book-launch {
  height: auto;
  width: 96vw;
  left: 2vw;
  top: 200px;
  background-color: white;
  margin: auto;
}

h2 {
  font-size: 72px;
  background: linear-gradient(135deg, #FC466B 0%, #3F5EFB);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

h2 {
  padding: 20px;
  font-size: 2em;
  font-family: "ff1";
  text-align: center; 
}

.youtube {
  display: flex;
  margin: auto;
  justify-content: center;
}